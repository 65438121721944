import PropTypes from 'prop-types';

import { CssBaseline, StyledEngineProvider } from '@mui/material';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { yellow, grey } from '@mui/material/colors';

import * as locales from '@mui/material/locale';

const AppTheme = ({ locale, children }) => {
    const theme = createTheme({
        palette: {
            application: {
                main: '#7e7e7e',
                light: '#afafaa',
                dark: '#5f5f61',
                gradient: 'linear-gradient(#afafaa, #afafaa 0%, #5f5f61 100%)',
                selected: '#afafaa',
                contrastText: '#eeedee',
            },
            header: {
                main: '#36393f',
                contrastText: '#fff'
            },
            logo: {
                light: '#afafaa',
                middle: '#7c7c7c',
                main: '#7e7e7e',
                dark: '#5f5f61',
                background: '#fff',
                constrastText: '#231815'
            },
            notification: {
                light: '#fbfbfb',
                main: '#fbfbfb',
                dark: '#fbfbfb',
                contrastText: grey[500]
            },
            search: {
                light: '#fbfbfb',
                main: '#fbfbfb',
                dark: '#fbfbfb',
                contrastText: grey[500]
            },
            grid: {
                header: grey[20],
                blankLine: '#ffffff',
                contrastLine: '#fafafa',
                hoverLine: '#666',
                light: '#fbfbfb',
                main: '#fbfbfb',
                dark: '#fbfbfb',
                contrastText: '#108c58'
            },
            new: {
                light: grey[500],
                main: grey[500],
                dark: grey[500],
                contrastText: '#fbfbfb'
            },
            edit: {
                light: '#09274d',
                main: '#09274d',
                dark: '#09274d',
                contrastText: '#fbfbfb'
            },
            delete: {
                light: '#c21614',
                main: '#c21614',
                dark: '#c21614',
                contrastText: '#fbfbfb'
            },
            required: {
                light: '#ffe3e2',
                main: '#ffe3e2',
                dark: '#ffe3e2',
                contrastText: '#a40925'
            },
            alert: {
                light: yellow[10],
                main: yellow[50],
                dark: yellow[100],
                contrastText: '#000'
            },
            background: {
                light: grey[10],
                main: grey[50],
                dark: grey[100],
                contrastText: '#000'
            },
            primary: {
                main: '#7e7e7e',
            },
            secondary: {
                main: '#afafaa',
            }
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        margin: "0 0 0 0"
                    }
                }
            }
        }
    },
        locales[locale]
    );

    const { breakpoints } = theme;

    theme.typography.h1 = {
        ...theme.typography.h1,
        [breakpoints.up('xs')]: {
            fontSize: '5rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '5.4rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '5.8rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '6rem',
        }
    };

    theme.typography.h2 = {
        ...theme.typography.h2,
        [breakpoints.up('xs')]: {
            fontSize: '3rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '3.25rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '3.5rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '3.75rem',
        }
    };

    theme.typography.h3 = {
        ...theme.typography.h3,
        [breakpoints.up('xs')]: {
            fontSize: '2.2rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '2.4rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '2.8rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '3rem',
        }
    };

    theme.typography.h4 = {
        ...theme.typography.h4,
        [breakpoints.up('xs')]: {
            fontSize: '1.75rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '1.95rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '2.05rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '2.125rem',
        }
    };

    theme.typography.h5 = {
        ...theme.typography.h5,
        [breakpoints.up('xs')]: {
            fontSize: '1rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '1.1rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '1.2rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '1.5rem',
        }
    };

    theme.typography.h6 = {
        ...theme.typography.h6,
        [breakpoints.up('xs')]: {
            fontSize: '0.9rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '1rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '1.1rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '1.25rem',
        }
    };

    theme.typography.subtitle1 = {
        ...theme.typography.subtitle1,
        [breakpoints.up('xs')]: {
            fontSize: '0.6rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '0.7rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '0.8rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '1rem',
        }
    };

    theme.typography.subtitle2 = {
        ...theme.typography.subtitle2,
        [breakpoints.up('xs')]: {
            fontSize: '0.55rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '0.65rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '0.75rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '0.875rem',
        }
    };

    theme.typography.body1 = {
        ...theme.typography.body1,
        [breakpoints.up('xs')]: {
            fontSize: '0.7rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '0.8rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '0.9rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '1rem',
        }
    };

    theme.typography.body2 = {
        ...theme.typography.body2,
        [breakpoints.up('xs')]: {
            fontSize: '0.75rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '0.8rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '0.825rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '0.875rem',
        }
    };

    theme.typography.button = {
        ...theme.typography.button,
        [breakpoints.up('xs')]: {
            fontSize: '0.75rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '0.8rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '0.825rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '0.875rem',
        }
    };

    theme.typography.caption = {
        ...theme.typography.caption,
        [breakpoints.up('xs')]: {
            fontSize: '0.6rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '0.65rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '0.7rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '0.75rem',
        }
    };

    theme.typography.overline = {
        ...theme.typography.overline,
        [breakpoints.up('xs')]: {
            fontSize: '0.6rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '0.65rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '0.7rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '0.75rem',
        }
    };
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

AppTheme.propTypes = {
    locales: PropTypes.string,
    children: PropTypes.node
};

export default AppTheme;