import { React, StrictMode } from "react";
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Translation from "translation";
import store, { persistor, history } from 'store';
import App from 'App';

Translation.init();

const defaultURI = '/control';
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  // <StrictMode>
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter history={history} basename={defaultURI}>
        <App locale={Translation.locale} />
      </BrowserRouter>
    </PersistGate>
  </Provider>
  // </StrictMode>
);

if (!window?.location?.pathname?.startsWith(defaultURI)) {
  window?.location?.replace(defaultURI);
}