import { lazy } from 'react';
import { Navigate } from "react-router-dom";
import TopLoader from 'components/loader/Loader';

const AuthMain = TopLoader(lazy(() => import('auth/layout')));
const Login = TopLoader(lazy(() => import('auth/pages/Login')));
const Register = TopLoader(lazy(() => import('auth/pages/Register')));
const PasswordRemember = TopLoader(lazy(() => import('auth/pages/PasswordRemember')));
const PasswordReset = TopLoader(lazy(() => import('auth/pages/PasswordReset')));

const LoginRoutes = {
    path: '/',
    element: <AuthMain />,
    children: [
        {
            path: '*',
            element: <Navigate to="/login" />
        },
        {
            path: 'login',
            element: <Login />
        },
        {
            path: 'register',
            element: <Register />
        },
        {
            path: 'password-remember',
            element: <PasswordRemember />
        },
        {
            path: 'password-reset',
            element: <PasswordReset />
        }
    ]
};

export default LoginRoutes;