import { createSlice } from "@reduxjs/toolkit";

const File = {
    fields: {
    },
    put: (state, action) => { state.storage = { ...state.storage, ...action.payload } },
    releaseAll: (state, action) => { for (const file in state?.storage) { window.URL.revokeObjectURL(file) }; state.storage = { ...FileState.storage, ...action.payload } }
};

const FileState = {
    storage: File.fields
};

const initialState = ({ ...FileState });

const FileBehavior = createSlice({
    name: "file",
    initialState,
    reducers: {
        putFile: File.put,
        releaseAllFiles: File.releaseAll
    }
});

export const { putFile, releaseAllFiles } = FileBehavior.actions;

export default FileBehavior.reducer;