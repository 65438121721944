import { createSlice } from '@reduxjs/toolkit';

const Account = {
  fields: {
    isLogged: false,
    keepLogged: false,
    member: null
  },
  login: (state, action) => {
    state.account.isLogged = true;
    state.account.keepLogged = action?.payload?.keepLogged;
    state.account.member = action?.payload?.member;
  },
  logout: (state, action) => {
    state.account.isLogged = false;
    state.account.member = null;
  },
  keepLogged: (state, action) => {
    if (!state?.account?.keepLogged) {
      Account.logout(state, action);
    }
  }
};

const AccountState = {
  account: Account.fields
};

const initialState = ({ ...AccountState });

const AccountBehavior = createSlice({
  name: 'account',
  initialState,
  reducers: {
    login: Account.login,
    logout: Account.logout,
    keepLogged: Account.keepLogged
  }
});

export const { login, logout, keepLogged } = AccountBehavior.actions;

export default AccountBehavior.reducer;