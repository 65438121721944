import { createSlice } from "@reduxjs/toolkit";
import { PageMode } from "common/uiWrapper";

const Page = {
    fields: {
        URL: null,
        mode: PageMode.NONE,
        kind: null,
        selected: null,
        data: [],
        lookup: {},
        file: {}
    },
    setURL: (state, action) => { state.page.URL = action.payload },
    setMode: (state, action) => { state.page.mode = action.payload },
    setKind: (state, action) => { state.page.kind = action.payload },
    setSelected: (state, action) => { state.page.selected = action.payload },
    setData: (state, action) => { state.page.data = action.payload },
    setLookup: (state, action) => { state.page.lookup = { ...state.page.lookup, ...action.payload } },
    setFile: (state, action) => { state.page.file = { ...state.page.file, ...action.payload } },
    changeLookupValue: (state, action) => { const lookup = state.page?.lookup[action?.payload?.name]; const lookupItem = lookup?.find((item) => (item[action?.payload?.findProperty] === action?.payload?.findValue)); lookupItem[action?.payload?.property] = action?.payload?.value; },
    reset: (state, action) => { state?.file?.forEach(file => window.URL.revokeObjectURL(file)); state.page = { ...PageState.page, ...action.payload } }
};

const PageState = {
    page: Page.fields
}

const initialState = ({ ...PageState });

const PageBehavior = createSlice({
    name: "page",
    initialState,
    reducers: {
        setURL: Page.setURL,
        setMode: Page.setMode,
        setKind: Page.setKind,
        setSelected: Page.setSelected,
        setData: Page.setData,
        setLookup: Page.setLookup,
        setFile: Page.setFile,
        changeLookupValue: Page.changeLookupValue,
        reset: Page.reset
    }
});

export const { setURL, setMode, setKind, setSelected, setData, setLookup, changeLookupValue, setFile, reset } = PageBehavior.actions;

export default PageBehavior.reducer;