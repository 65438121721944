import { persistor } from 'store';

const AppCleanUp = () => {
    persistor.purge();
    persistor.flush();
    persistor.persist();

    console.info("State cleaner has been executed successfully.");
    return (
        <>
        </>
    );
}

export default AppCleanUp;