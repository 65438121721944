import { createSlice } from "@reduxjs/toolkit";

const Context = {
    fields: {
    },
    set: (state, action) => { state.features[action?.payload?.name] = { ...state.features[action?.payload?.name], ...action?.payload?.value } },
    clean: (state, action) => { state.features[action?.payload?.name] = {} },
    flush: (state, action) => { state.features = { ...ContextState.features, ...action.payload } }
};

const ContextState = {
    features: Context.fields
}

const initialState = ({ ...ContextState });

const ContextBehavior = createSlice({
    name: "context",
    initialState,
    reducers: {
        setContext: Context.set,
        cleanContext: Context.clean,
        flushContext: Context.flush
    }
});

export const { setContext, cleanContext, flushContext } = ContextBehavior.actions;

export default ContextBehavior.reducer;