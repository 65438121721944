import i18next from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import { language, namespace, languageDefault, namespaceDefault, resources, languageMapping } from './settings';

const Translation = () => {
    let ns = [];

    const init = () => {
        i18next.use(initReactI18next).init({
            lng: language,
            ns: namespace,
            fallbackLng: languageDefault,
            fallbackNS: namespaceDefault,
            resources: resources,
            interpolation: { escapeValue: false }
        });
    };

    const locale = languageMapping[language];

    const useText = (namespaces) => {
        ns = [...new Set(namespaces.concat(namespace))];
        const { t } = useTranslation(namespaces);
        return t;
    };

    const text = (text) => {
        ns = [...new Set(ns.concat(namespace))];
        const t = i18next.getFixedT(null, ns, "");

        let translated = '';
        if (Array.isArray(text)) {
            for (const element of text) {
                translated = `${translated}・${t(element)}`;
            }
            translated = translated.substring(1, translated.length);
        } else {
            translated = t(text);
        }

        return translated;
    }

    return { init, locale, useText, text };
}

export default Object.freeze(Translation());