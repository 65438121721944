import { lazy } from 'react';
import { Navigate } from "react-router-dom";
import Loader from 'components/loader/Loader';
import AppCleanUp from 'app/pages/cleanup';

const AppMain = Loader(lazy(() => import('app/layout')));
const AppDashboard = Loader(lazy(() => import('app/pages/dashboard')));

const AppRoutes = {
    path: '/',
    element: <AppMain />,
    children: [
        {
            path: '*',
            element: <Navigate to="/login" />
        },
        {
            path: 'dashboard',
            element: <AppDashboard />
        },
        {
            path: 'clean-up',
            element: <AppCleanUp />
        }

    ]
};

export default AppRoutes;