import React from 'react';
import AppTheme from 'theme';
import MainRoutes from "routes";

const App = ({locale}) => (
    <AppTheme locale={locale}>
        <MainRoutes />
    </AppTheme>
);
 
export default App;