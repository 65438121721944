import app_en from './locales/en/app.json';
import common_en from './locales/en/common.json';
import locale_en from './locales/en/locale.json';
import menu_en from './locales/en/menu.json';
import message_en from './locales/en/message.json';
import account_en from './locales/en/account.json';
import notification_en from './locales/en/notification.json';
import approval_en from './locales/en/approval.json';
import organization_en from './locales/en/organization.json';
import member_en from './locales/en/member.json';
import team_en from './locales/en/team.json';
import plan_en from './locales/en/plan.json';
import feature_en from './locales/en/feature.json';

import app_jp from './locales/jp/app.json';
import common_jp from './locales/jp/common.json';
import locale_jp from './locales/jp/locale.json';
import menu_jp from './locales/jp/menu.json';
import message_jp from './locales/jp/message.json';
import account_jp from './locales/jp/account.json';
import notification_jp from './locales/jp/notification.json';
import approval_jp from './locales/jp/approval.json';
import organization_jp from './locales/jp/organization.json';
import member_jp from './locales/jp/member.json';
import team_jp from './locales/jp/team.json';
import plan_jp from './locales/jp/plan.json';
import feature_jp from './locales/jp/feature.json';

const language = navigator.language;
const namespace = ["application", "common", "locale", "menu", "message", "notification"];
const languageDefault = "en-US";
const namespaceDefault = "application";
const languageMapping = { "en": "enUS", "ja": "jaJP", "en-US": "enUS", "ja-JP": "jaJP" };

const resources = {
    'en-US':
    {
        "application": app_en,
        "common": common_en,
        "locale": locale_en,
        "menu": menu_en,
        "message": message_en,
        "account": account_en,
        "notification": notification_en,
        "approval": approval_en,
        "organization": organization_en,
        "member": member_en,
        "team": team_en,
        "plan": plan_en,
        "feature": feature_en
    },
    'ja':
    {
        "application": app_jp,
        "common": common_jp,
        "locale": locale_jp,
        "menu": menu_jp,
        "message": message_jp,
        "account": account_jp,
        "notification": notification_jp,
        "approval": approval_jp,
        "organization": organization_jp,
        "member": member_jp,
        "team": team_jp,
        "plan": plan_jp,
        "feature": feature_jp
    }
};

export { language, namespace, languageDefault, namespaceDefault, resources, languageMapping }; 