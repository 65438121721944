import { lazy } from 'react';
import { Navigate } from "react-router-dom";
import Loader from 'components/loader/Loader';

const ControlPanelMain = Loader(lazy(() => import('admin/layout')));

const Organization = Loader(lazy(() => import('admin/pages/organization')));

const Profile = Loader(lazy(() => import('admin/pages/profile')));

const NotificationMaster = Loader(lazy(() => import('admin/notification/master')));
const Notification = Loader(lazy(() => import('admin/notification')));

const Dashboard = Loader(lazy(() => import('admin/pages/dashboard')));

const Setting = Loader(lazy(() => import('admin/pages/setting')));

const AppRequest = Loader(lazy(() => import('admin/pages/appRequest')));

const MemberMaster = Loader(lazy(() => import('admin/pages/member/master')));
const MemberListing = Loader(lazy(() => import('admin/pages/member')));
const MemberForm = Loader(lazy(() => import('admin/pages/member/form')));

const TeamMaster = Loader(lazy(() => import('admin/pages/team/master')));
const TeamListing = Loader(lazy(() => import('admin/pages/team')));
const TeamForm = Loader(lazy(() => import('admin/pages/team/form')));

const BillingSetting = Loader(lazy(() => import('admin/pages/billingSetting/master')));
const BillingSettingForm = Loader(lazy(() => import('admin/pages/billingSetting')));

const SubscriptionMaster = Loader(lazy(() => import('admin/pages/subscription/master')));
const SubscriptionListing = Loader(lazy(() => import('admin/pages/subscription')));
const SubscriptionTransactions = Loader(lazy(() => import('admin/pages/subscription/transactions')));

const PlanMaster = Loader(lazy(() => import('admin/pages/plan/master')));
const PlanListing = Loader(lazy(() => import('admin/pages/plan')));
const PlanForm = Loader(lazy(() => import('admin/pages/plan/form')));
const PlanPOC = Loader(lazy(() => import('admin/pages/plan/poc')));

const PlanVersionMaster = Loader(lazy(() => import('admin/pages/plan/version/master')));
const PlanVersionListing = Loader(lazy(() => import('admin/pages/plan/version')));
const PlanVersionForm = Loader(lazy(() => import('admin/pages/plan/version/form')));

const FeatureMaster = Loader(lazy(() => import('admin/pages/feature/master')));
const FeatureListing = Loader(lazy(() => import('admin/pages/feature')));
const FeatureForm = Loader(lazy(() => import('admin/pages/feature/form')));

const AdminRoutes = {
    path: '/panel',
    element: <ControlPanelMain />,
    children: [
        {
            path: '*',
            element: <Navigate to="/admin/dashboard" />
        },
        {
            path: 'organization',
            element: <Organization />
        },
        {
            path: 'profile',
            element: <Profile />
        },
        {
            path: 'notification',
            element: <NotificationMaster />,
            children: [
                {
                    path: '',
                    element: <Notification />
                }
            ]
        },
        {
            path: 'dashboard',
            element: <Dashboard />
        },
        {
            path: 'setting',
            element: <Setting />
        },
        {
            path: 'app-request',
            element: <AppRequest />
        },
        {
            path: 'billing-setting',
            element: <BillingSetting />,
            children: [
                {
                    path: 'form',
                    element: <BillingSettingForm />
                }
            ]
        },
        {
            path: 'member',
            element: <MemberMaster />,
            children: [
                {
                    path: 'list',
                    element: <MemberListing />
                },
                {
                    path: 'form',
                    element: <MemberForm />
                }
            ]
        },
        {
            path: 'team',
            element: <TeamMaster />,
            children: [
                {
                    path: 'list',
                    element: <TeamListing />
                },
                {
                    path: 'form',
                    element: <TeamForm />
                }
            ]
        },
        {
            path: 'subscription',
            element: <SubscriptionMaster />,
            children: [
                {
                    path: 'list',
                    element: <SubscriptionListing />
                },
                {
                    path: 'transactions/:company',
                    element: <SubscriptionTransactions />
                }
            ]
        },
        {
            path: 'plan',
            element: <PlanMaster />,
            children: [
                {
                    path: 'list',
                    element: <PlanListing />
                },
                {
                    path: 'form',
                    element: <PlanForm />
                },
                {
                    path: 'poc',
                    element: <PlanPOC />
                },
                {
                    path: 'version',
                    element: <PlanVersionMaster />,
                    children: [
                        {
                            path: 'list',
                            element: <PlanVersionListing />
                        },
                        {
                            path: 'form',
                            element: <PlanVersionForm />
                        }
                    ]
                }
            ]
        },
        {
            path: 'feature',
            element: <FeatureMaster />,
            children: [
                {
                    path: 'list',
                    element: <FeatureListing />
                },
                {
                    path: 'form',
                    element: <FeatureForm />
                }
            ]
        }
    ]
};

export default AdminRoutes;