import PropTypes from 'prop-types';
import { Suspense } from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';

const LoaderWrapper = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 2001,
    width: '100%',
    '& > * + *': {
        marginTop: theme.spacing(2)
    }
}));

const Loader = () => (
    <LoaderWrapper>
        <LinearProgress color="application" />
    </LoaderWrapper>
);


const TopLoader = (Component) => (props) => (
    <Suspense fallback={<Loader />}>
        <Component {...props} />
    </Suspense>
);

TopLoader.propTypes = {
    Component: PropTypes.Component
};

export default TopLoader;
