const SystemMode = {
    DEFAULT: 'DEFAULT',
    SALE: 'SALE',
    BUY: 'BUY'
};

const PageMode = {
    NONE: 'NONE',
    LISTING: 'LISTING',
    INSERTING: 'INSERTING',
    EDITING: 'EDITING',
    DELETING: 'DELETING'
};

const ColorMode = {
    LIGHT: 'light',
    DARK: 'dark',
};

const MsgType = {
    NONE: "none",
    ERROR: "error",
    SUCCESS: "success",
    WARNING: "warning",
    INFO: "info"
};

const PermissionStatus = Object.freeze({
    GRANTED: 'granted',
    DENIED: 'denied',
    PROMPT: 'prompt'
});

const PermissionType = Object.freeze({
    CLIPBOARD_WRITE: 'clipboard-write'
});

const BrowserUI = {
    mobileBreakpoint: 768,
    tabletBreakpoint: 1024,

    location: () => {
        return window.location.origin;
    },

    screenWidth: () => {
        return window.innerWidth;
    },

    screenHeight: () => {
        return window.innerHeight;
    },

    menuWidth: () => {
        if (BrowserUI.isMobile()) {
            return "325";
        } else {
            return "275";
        }
    },

    isTablet: () => {
        return ((window.innerWidth < BrowserUI.tabletBreakpoint) || (navigator.userAgent.indexOf("Mobi") > -1));
    },

    isMobile: () => {
        return ((window.innerWidth < BrowserUI.mobileBreakpoint) || (navigator.userAgent.indexOf("Mobi") > -1));
    },

    isRefreshing: () => {
        return (window.performance.getEntriesByType('navigation').map((nav) => nav.type).includes('reload'));
    },

    scrollTo: (id) => {
        setTimeout(() => {
            const element = document.getElementById(id);
            (element) && (element.scrollIntoView({ behavior: 'smooth' }));
        }, 100);
    },

    copyClipboard: async (text) => {
        navigator?.clipboard?.writeText(text || '');
    },

    hasPermission: async (permissionType) => {
        return navigator?.permissions?.query({ name: permissionType });
    },
};

export { BrowserUI, SystemMode, PageMode, ColorMode, MsgType, PermissionStatus, PermissionType };